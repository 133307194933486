import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "record-transaction-outcome",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#record-transaction-outcome",
        "aria-label": "record transaction outcome permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Record transaction outcome`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "virtual-terminal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#virtual-terminal",
        "aria-label": "virtual terminal permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Virtual Terminal`}</h2>
    <h3 {...{
      "id": "transaction-states",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#transaction-states",
        "aria-label": "transaction states permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transaction states`}</h3>
    <p>{`For Virtual Terminal transctions, there is a simple whole of transaction state indicated by `}<strong parentName="p">{`status`}</strong>{`. `}<strong parentName="p">{`Status`}</strong>{` can be one of:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Cancelled`}</strong>{`: transaction cancelled by provider or rejected by patient.`}</li>
      <li parentName="ul"><strong parentName="li">{`Completed`}</strong>{`: transaction is completed, with payment approved.`}</li>
      <li parentName="ul"><strong parentName="li">{`Pending`}</strong>{`: transaction created, awaiting approval by patient.`}</li>
    </ul>
    <h3 {...{
      "id": "response-payload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#response-payload",
        "aria-label": "response payload permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Response payload`}</h3>
    <p>{`When a transaction is processed, the SDK will pass through the transaction details to the first parameter of the onSuccess hook.`}</p>
    <p>{`The response payload contains a number of attributes with a complete description of the transaction model in our online API documentation.`}</p>
    <p>{`Key attributes include:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a unique transaction level identifier generated by Medipass`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`overall transaction status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payments[].amount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`amount paid`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payments[].status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`status of the payment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payments[].gatewayDescription`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`payment network response, if failed/declined, reason fdescription`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2 {...{
      "id": "funder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#funder",
        "aria-label": "funder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Funder`}</h2>
    <h3 {...{
      "id": "transaction-and-item-level-states",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#transaction-and-item-level-states",
        "aria-label": "transaction and item level states permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transaction and item level states`}</h3>
    <p>{`The following terminology is used for each Medipass transaction:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Item code`}</strong>{` is a single item or service.`}</li>
      <li parentName="ul"><strong parentName="li">{`Claim`}</strong>{` is the total set of item codes processed in one transaction. A complete claim could include a number of item codes if you have completed a more complex service for your patient.`}</li>
      <li parentName="ul"><strong parentName="li">{`Patient funded payment`}</strong>{` is the gap or other payment made directly by a patient, usually by credit/debit card. This is only applicable for Medicare, HICAPS and Patient funded transactions.`}</li>
      <li parentName="ul"><strong parentName="li">{`Transaction`}</strong>{` is a combined set of item codes that may include a combination of a claim and a patient payment.`}</li>
    </ul>
    <p>{`Each of these components have their own statuses and error codes.`}</p>
    <p>{`Medipass statuses and error codes include:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Item code`}</strong>{` status can be one of:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Pending`}</strong>{`: we are awaiting a response from the funder.`}</li>
          <li parentName="ul"><strong parentName="li">{`Under Review`}</strong>{`: funder has advised that they have received the item code but it is under review.`}</li>
          <li parentName="ul"><strong parentName="li">{`Approved`}</strong>{`: item code is approved.`}</li>
          <li parentName="ul"><strong parentName="li">{`Rejected`}</strong>{`: item code is rejected. In this case we may offer further information that has been provided to us from Funder to help you determine how best to resubmit the claim in order for it to be approved.`}</li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Claim`}</strong>{` status can be one of:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Submitted`}</strong>{`: Medipass has submitted this claim to the funder.`}</li>
          <li parentName="ul"><strong parentName="li">{`Accepted`}</strong>{`: funder Accepted (and therefore approved) the claim.`}</li>
          <li parentName="ul"><strong parentName="li">{`Under Review`}</strong>{`: funder advised that they have received the claim but it is under review.`}</li>
          <li parentName="ul"><strong parentName="li">{`Rejected`}</strong>{`: funder advised that the claim has been rejected. In this case we may offer further information that has been provided to us from the funder to help you determine how best to resubmit the claim for approval.`}</li>
          <li parentName="ul"><strong parentName="li">{`Payment made`}</strong>{`: funder advised that payment has been sent to the nominated account.`}</li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Patient funded payment`}</strong>{` status can be one of:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Pending`}</strong>{`: the payment request has been sent to the patient and is awaiting their approval.`}</li>
          <li parentName="ul"><strong parentName="li">{`Voided`}</strong>{`: the payment request was cancelled, either by the patient or by the provider.`}</li>
          <li parentName="ul"><strong parentName="li">{`Approved`}</strong>{`: the payment request has been approved by the patient.`}</li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Transaction`}</strong>{` status can be one of:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Pending`}</strong>{`: the transaction is pending because a component (such as the patient payment) has not been finalised.`}</li>
          <li parentName="ul"><strong parentName="li">{`Cancelled`}</strong>{`: a component of the transaction was cancelled.`}</li>
          <li parentName="ul"><strong parentName="li">{`Under Review`}</strong>{`: a component of the transaction is under review`}</li>
          <li parentName="ul"><strong parentName="li">{`Completed`}</strong>{`: payment has been made, though the funder claim may still be outstanding.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`For Medicare claims, Medipass will show both the return code and the description as advised by Medicare. These are either:`}</p>
    <ul>
      <li parentName="ul">{`four digit return codes which are sent when errors happen.
`}<a parentName="li" {...{
          "href": "https://www.humanservices.gov.au/sites/default/files/2017/11/4-digit-reason-codes.csv"
        }}>{`https://www.humanservices.gov.au/sites/default/files/2017/11/4-digit-reason-codes.csv`}</a></li>
      <li parentName="ul">{`three digit reason codes which provide information about how a claim was assessed: `}<a parentName="li" {...{
          "href": "https://www.humanservices.gov.au/sites/default/files/2018/07/medicare-reason-codes.csv"
        }}>{`https://www.humanservices.gov.au/sites/default/files/2018/07/medicare-reason-codes.csv`}</a></li>
    </ul>
    <p>{`A full list of all Medicare return codes is posted at: `}<a parentName="p" {...{
        "href": "https://www.humanservices.gov.au/organisations/health-professionals/enablers/medicare-digital-claiming-return-codes/33171"
      }}>{`https://www.humanservices.gov.au/organisations/health-professionals/enablers/medicare-digital-claiming-return-codes/33171`}</a></p>
    <h3 {...{
      "id": "response-payload-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#response-payload-1",
        "aria-label": "response payload 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Response payload`}</h3>
    <p>{`When a transaction is successful, the SDK will pass through the transaction details to the first parameter of the `}<inlineCode parentName="p">{`onSuccess`}</inlineCode>{` hook.`}</p>
    <p>{`The response payload will differ for each funder type, but the fundamental items that you need to check in the response remain similar. A complete description of the transaction model is provided in our `}<a parentName="p" {...{
        "href": "https://developers.medipass.io/docs"
      }}>{`online API documentation`}</a>{`.`}</p>
    <p>{`Key attributes include:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a unique transaction level identifier generated by Medipass`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`overall transaction status, includes any claim and payment component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`amountClaimsBenefit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`total benefit paid by funder`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`amountBalance`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`any unpaid amount on aggregate charges`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims[].status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`overall claim status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims[].gatewayRefId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`unique transaction reference from funder`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims[].claimItems[].status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`claim item status - some may be accepted and others declined`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims[].claimItems[].amountBenefit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`benefit paid on item by funder`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims[].claimItems[].amountGap`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`gap on item (for HICAPS claims)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`claims[].claimItems[].clientMessage`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`message to display to user (usually for declined items)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payments[].amount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`amount paid by the payment method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payments[].status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`status of the payment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`externalReference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the funder generates a unique transaction or approval number, those references will be available in the externalReferences model. These references are used for transaction inquiries with funders.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`links`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For some funders, there are links that enable you to read-back the transaction or put through a cancellation for the transaction`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      